@import "../../index.css";

.input__width {
	width: 660px;
}

.btn {
	width: 172px;
	margin-bottom: 48px;
}

.space {
	height: 48px;
}
.buttonContainer {
	display: flex;
	justify-content: flex-end;
	gap: 1rem; /* Space between buttons */
	margin-top: 1rem; /* Space above the buttons */
}
/* Container for the entire section */
.goodsInfoContainer {
	width: 100%;
	padding: 20px;
	background-color: #f1f7ff;
}

.goodsInfoContainer h2 {
	font-size: 1.5rem;
	margin-bottom: 1rem;
}

/* Instructions box styling */
.instructionsBox {
	background-color: #fff7d6;
	padding: 15px;
	margin-bottom: 20px;
	border-left: 5px solid #ffda75;
}

.instructionsBox p {
	font-weight: bold;
}

.instructionsBox ol {
	padding-left: 20px;
	list-style-type: decimal;
}

.instructionsBox li {
	margin-bottom: 10px;
}

/* Layout for download and upload sections */
.uploadContainer {
	display: flex;
	justify-content: space-between;
	gap: 20px;
}

.uploadContainer h3 {
	font-size: 1rem;
	margin-bottom: 10px;
}

/* Button styling */
.btn1 {
	background-color: #28a745;
	color: white;
	padding: 10px 15px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

/* .btn:hover {
	background-color: #218838;
} */

/* File upload section styling */
.uploadFile label {
	background-color: #28a745;
	color: white;
	padding: 10px 15px;
	border-radius: 5px;
	cursor: pointer;
}

.uploadFile input[type="file"] {
	display: none; /* Hide the default file input */
}

.fileLabel {
	background-color: #28a745;
	padding: 10px 15px;
	color: white;
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
}

.img {
	color: #ffff;
}
.space {
	margin-bottom: 5px;
}
/* Styling for required fields */
.required {
	color: red;
	font-weight: bold;
}
@media (max-width: 768px) {
	.btn {
		width: 100%; /* Make the button take full width */
		display: block; /* Ensure it takes up the full block */
		margin: 0 auto; /* Center the button */
	}
}
/* Modal.css */
.modaloverlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal {
	background: white;
	border-radius: 8px;
	max-width: 800px;
	width: 100%;
	overflow-y: auto;
	padding: 40px;
	max-height: 90vh;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modalheader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #eee;
	padding-bottom: 10px;
}

.modalclosebutton {
	background: none;
	border: none;
	font-size: 24px;
	cursor: pointer;
}

.modalcontent {
	margin-top: 15px;
	width: 100%;
	font-size: 14px; /* Shrink text size */
	line-height: 1.4; /* Adjust line spacing */
}

.table_container {
	width: 100%; /* Set the width you want */
	overflow-x: auto; /* Enable horizontal scrolling */
	-webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}
h3 {
	font-weight: 600;
	font-size: 24px;
	line-height: 22.9px;
	margin-bottom: 10px;
}

p {
	font-size: 14px;
	line-height: 22.6px;
	font-weight: 400;
}

/* .container {
	font-family: Arial, sans-serif;
	background-color: #f0f7ff;
	padding: 20px;
	border-radius: 8px;
	width: 80%;
	margin: auto;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
} */

/* .container {
	margin-bottom: 64px;
} */

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	border-bottom: 2px;
}

.companyName {
	font-size: 24px;
	font-weight: bold;
	color: #333;
}

.actions {
	display: flex;
	gap: 10px;
}

.button {
	color: #0073e6;
	background-color: transparent;
	border: none;
	cursor: pointer;
	font-size: 14px;
}

.details,
.contactInfo {
	margin-top: 20px;
}

.row,
.infoRow {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.label {
	font-weight: bold;
	color: #666;
	width: 30%;
}

.value {
	width: 70%;
	color: #333;
}

.statusPaid {
	color: green;
}

.statusApproved {
	color: #008000;
}
/* CompanyInfo.module.css */
.container {
	display: flex;
	gap: 20px;
}

.infoSection {
	width: 48%;
	/* Adjust width to fit two columns with margin */
	float: left;
	margin-right: 2%;
	margin-bottom: 64px;
	/* Add space between columns */
	/* padding-right: 10px; */
	/* border-right: 1px solid #dce6f2; */
}

.infoSection h2 {
	font-size: 24px;
	margin-bottom: 15px;
}

.infoSection p {
	margin: 5px 0;
	color: #333;
}

.infoSection strong {
	color: #000;
}

.attachmentSection {
	flex: 1;
	/* padding-left: 10px; */
}

.attachmentHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #e7f7e7;
	padding: 10px;
	border-radius: 8px;
}

.attachmentHeader h3 {
	margin: 0;
	font-size: 18px;
	color: #333;
}

.downloadAll {
	background-color: #5cb85c;
	color: #fff;
	border: none;
	border-radius: 5px;
	padding: 8px 12px;
	cursor: pointer;
}

.downloadAll:hover {
	background-color: #4cae4c;
}

.attachmentList {
	list-style: none;
	padding: 0;
	margin-top: 10px;
	max-height: 300px;
	overflow-y: auto;
}

.attachmentItem {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px;
	border-bottom: 1px dotted #dce6f2;
}

.fileIcon {
	margin-right: 10px;
}

.fileName {
	flex: 1;
}

.viewButton {
	color: #5cb85c;
	background: none;
	border: none;
	cursor: pointer;
	font-weight: bold;
	margin-bottom: 40px;
}

.viewButton:hover {
	text-decoration: underline;
}
.radio__button_div > div {
	display: flex;
}

.radio__button_div > div > div {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
}

.radio__button_div > div > div input {
	margin-right: 10px;
}

.radio__button_div > div > div label {
	margin-top: -10px;
}

.radio__button_div > div > div:not(:last-child) {
	margin-right: 35px;
}
.buttonContainer {
	display: flex;
	justify-content: flex-end;
	gap: 1rem; /* Space between buttons */
	margin-top: 1rem; /* Space above the buttons */
}
/* Modal.css */
.modaloverlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal {
	background: white;
	border-radius: 8px;
	max-width: 800px;
	width: 100%;
	overflow-y: auto;
	padding: 40px;
	max-height: 90vh;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modalheader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #eee;
	padding-bottom: 10px;
}

.modalclosebutton {
	background: none;
	border: none;
	font-size: 24px;
	cursor: pointer;
}

.modalcontent {
	margin-top: 15px;
	width: 100%;
	font-size: 14px; /* Shrink text size */
	line-height: 1.4; /* Adjust line spacing */
}
.loadingIndicator {
	margin-top: 5px;
	font-size: 14px;
	color: #1f46aa;
}

.pending {
	color: var(--primary-05);
}

@media (max-width: 768px) {
	.container {
		width: 100%;
		margin-right: 0;
		display: grid;
		grid-template-columns: 1fr;
		justify-self: center;
	}
	.attachmentSection {
		width: 100%;
	}
	.infoSection {
		width: 100%;
	}
	.viewButton {
		font-size: 14px;
		justify-self: center;
	}
	p {
		font-size: 14px;
	}
}
