.table {
	width: 80%;
	max-width: 720px;
	border-collapse: collapse;
	margin-bottom: 20px;
}

.table th,
.table td {
	border-bottom: 1px solid var(--light-grey-00);
	padding: 10px;
	text-align: left;
}

.table th:first-child,
.table td:first-child {
	border-left: none;
}

.table th:last-child,
.table td:last-child {
	border-right: none;
}

.table th {
	background-color: var(--light-grey-00);
}

.description {
	max-width: 570px;
}
.editBtn,
.deleteBtn {
	background: none;
	border: none;
	cursor: pointer;
	font-size: 14px;
	margin-right: 5px;
	padding: 5px 10px;
	outline: none;
}
.editBtn:hover,
.deleteBtn:hover {
	background: none !important;
	border: none !important;
	color: inherit !important;
	box-shadow: none !important;
	text-decoration: none !important;
	opacity: 1 !important;
	transform: none !important;
}
.form_new {
	width: 62%;
	margin-bottom: 20px;
}
.form_edit {
	width: 700px;
}

.label {
	display: block;
	margin-bottom: 5px;
	font-weight: bold;
}

.input {
	width: 100%;
	padding: 8px;
	border: 1px solid var(--light-grey-00);
	border-radius: 4px;
	margin-bottom: 24px;
	min-height: 95px;
	vertical-align: top;
	resize: none;
}
.create__btn {
	width: 198px;
}
.cancel__btn {
	width: 116px;
}

.add__btn {
	width: 112px;
	margin-left: 20px;
}

.ctn__btn {
	/* width: 100px; */
	height: 31px !important;
}

.delctn__btn {
	width: 80px;
	height: 31px !important;
	background-color: var(--neutrals-white);
	color: #202020;
	border-radius: 4px;
	border: 1px solid #d8d8d8;
}
.delctn__btn_yes {
	width: 80px;
	height: 31px !important;
	background-color: #d73e3e !important;
	color: var(--neutrals-white) !important;
	height: 47px;
	border-radius: 4px !important;
	border: none !important;
}

.cancelsmall__btn {
	height: 31px !important;
	width: 80px;
	border-color: var(--light-grey-00) !important ;
	color: black !important;
}

.button__container {
	display: flex;
	justify-content: flex-end;
	gap: 20px;
	margin-top: 32px;
	max-width: 62%;
	margin-bottom: 47px;
}
.button__flexend {
	display: flex;
	justify-content: flex-end;
}
.modal__button {
	display: flex;
	justify-content: center;
	gap: 20px;
	margin-top: 20px;
}
.modal_container {
	width: 339px;
}

.modal_text {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
	line-height: 22.65px;
	font-size: 14px;
	text-align: center;
}
.cancelBtn,
.publishBtn {
	padding: 10px 15px;
	border: none;
	cursor: pointer;
}

.cancelBtn {
	background-color: #ccc;
}

.publishBtn {
	background-color: #28a745;
	color: white;
}
.actionImg {
	height: 24px;
	width: 24px;
}
.action__Img_flexend {
	display: flex;
	justify-content: flex-end;
	height: 24px;
	width: 24px;
	justify-self: flex-end;
	margin-right: 13px;
	cursor: pointer;
}

/* delete modal */
.delete__modal_Cont {
	width: 339px;
}
.delmodalbtn {
	display: flex;
	justify-content: center;
	gap: 20px;
	margin-top: 16px;
}
.delete__content {
	line-height: 22.65px;
	font-size: 14px;
	text-align: center;
	color: #202020;
}

.edit__Header_Container {
	height: 48px;
	width: 100%;
	position: absolute;
	top: 0;
	right: 0;
	background-color: #000;
	color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
}
.edit__Header_btnI {
	background: none !important;
	border: none !important;
	color: inherit !important;
	box-shadow: none !important;
	text-decoration: none !important;
	opacity: 1 !important;
	transform: none !important;
}
.edit__Header_btnI:hover {
	background: none !important;
	border: none !important;
	color: inherit !important;
	box-shadow: none !important;
	text-decoration: none !important;
	opacity: 1 !important;
	transform: none !important;
}
.edit__news_label {
	margin-top: 42px;
	font-size: 14px;
}
.actions__div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 52px;
}
.editHeader {
	font-size: 20px;
	font-weight: 600px;
	line-height: 24.2px;
	margin: 0;
}

.update__btn {
	width: 208px;
}
.editHeaderbtn {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 32px;
}

@media (max-width: 768px) {
	.form_edit {
		width: 100%;
	}
	.delete__modal_Cont {
		width: 100%;
	}
	.modal_container {
		width: 100%;
	}
}
