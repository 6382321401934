* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.TIN__announcement {
	background-color: var(--red-01);
	font-size: 12px;
	color: #fff;
	padding: 5px 10px;
	text-align: center;
	z-index: 3;
}

.TIN__announcement__toBeHidden {
	display: none;
}

.TIN__announcement__close {
	padding: 0 5px;
	margin-top: -2px;
	float: right;
	background: none;
	border: none;
	font-size: 12px !important;
}

.TIN__announcement__close:hover {
	box-shadow: none;
}

.heroSection {
	width: 100%;
	height: 561px;
	background-image: url(../assets/Home/homePageMobileBg.svg);
	background-repeat: no-repeat;
	background-size: cover;
	padding: 315px 10px 14px;
}

.banner__desc {
	max-width: 370px;
	height: 228px;
	padding: 16px;
	background: rgba(11, 45, 24, 0.8);
}

.banner__desc h1 {
	font-size: 24px;
	line-height: 28px;
	color: #fff;
	font-weight: 800;
	margin-top: -5px;
}

.word {
	position: absolute;
	opacity: 0;
}

.letter {
	display: inline-block;
	transform-origin: center center 25px;
	transition: 0.38s ease;
}

.letter.in {
	transform: rotateX(0deg);
	opacity: 1;
}

.letter.out {
	transform: rotateX(90deg);
	opacity: 0;
}

.letter.behind {
	transform: rotateX(-90deg);
	opacity: 0;
}

.enuguState {
	color: #f78c01;
}

.central {
	color: #fff;
}

.payment {
	color: #3abd6e;
}

.banner__desc h2 {
	font-size: 16px;
	color: #fff;
	font-weight: 700;
	text-transform: uppercase;
}

.banner__desc h2 span {
	border-bottom: 2px solid #fff;
}

.banner__desc .banner__buttons {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
}

.banner__buttons > div:last-of-type {
	grid-column-start: 1;
	grid-column-end: 3;
}

.dropdown {
	position: relative;
}

.dropdown button {
	width: 100%;
	padding: 10px;
	font-size: 12px;
	border-radius: 4px;
}

.dropdown button:focus {
	outline: none;
}

.dropdown_menu {
	width: 100%;
	position: absolute;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	position: absolute;
	border-radius: 4px;
	box-shadow: 0px 6px 15px 0 rgba(0, 0, 0, 0.12);
	margin-top: 5px;
}

.dropdown ul {
	margin-bottom: 0;
}

.dropdown_menu li {
	width: 100%;
	list-style: none;
	display: flex;
}

.dropdown_menu li:hover {
	background-color: #f5f5f5;
	border-radius: 4px;
}

.dropdown_menu li a {
	width: 100%;
	color: #202020;
	text-decoration: none;
}

.nav__payment_btn,
.nav__payment_btn:active,
.nav__payment_btn:hover,
.nav__payment_btn.active:hover {
	color: rgba(47, 153, 89, 1) !important;
	background-color: #fff !important;
	box-shadow: none;
	border: 1px solid rgba(47, 153, 89, 1);
	text-decoration: none;
	outline: none;
	padding: 8px;
	border-radius: 4px;
	width: 137px;
}

.banner_payWith_btn,
.banner_payWith_btn:active,
.banner_payWith_btn:hover {
	color: rgba(47, 153, 89, 1);
	background-color: #fff !important;
	box-shadow: none;
	border: 1px solid rgba(47, 153, 89, 1);
	text-decoration: none;
	outline: none;
	border-radius: 4px;
}

.esbn_btn,
.esbn_btn:active,
.esbn_btn:hover,
.esbn_btn.active:hover {
	color: #fff !important;
	background-color: #34aa63 !important;
	border: 1px solid #34aa63;
	box-shadow: none;
}

.TIN__btn {
	color: #fff;
	background-color: var(--red-01);
	border: none;
}

.TIN__btn:hover {
	box-shadow: none;
}

.gatewayContent {
	padding: 5px 10px;
}

.payments button :active {
	border: none;
	text-align: none;
	outline: none;
}

@media (min-width: 768px) {
	.TIN__announcement {
		font-size: 13px;
	}

	.TIN__announcement__toBeHidden {
		display: inline;
	}

	.TIN__announcement__close {
		font-size: 14px !important;
		margin-top: -2px;
	}

	.heroSection {
		width: 100%;
		background-image: url(../assets/Home/homePageBg.svg);
		padding: 300px 420px 20px 50px;
	}

	.banner__desc {
		width: 450px;
		max-width: 370px;
		height: 248px;
		padding: 24px;
	}

	.banner__desc h1 {
		font-size: 28px;
		line-height: 30px;
	}

	.banner__desc h2 {
		font-size: 14px;
	}
}

@media (min-width: 1024px) {
	.heroSection {
		width: 100%;
		background-image: url(../assets/Home/homePageBg.svg);
		height: 540px;
		padding: 265px 970px 45px 118px;
	}

	.banner__desc h1 {
		font-size: 30px;
		line-height: 34px;
	}

	.banner__desc h2 {
		font-size: 16px;
	}
}

/* heroSection Ended */

/* navbar */
.header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 16px 118px 12px 118px;
	position: fixed;
	background-color: #fff;
	z-index: 3;
}

.header > .mobile__nav {
	display: none;
}

.mobile__header_logo {
	display: none;
	height: 80px;
}

.desktop__header_logo {
	width: 56px;
	height: 56px;
}

.desktop__header_logo > img {
	height: inherit;
}

.header > nav {
	display: flex;
	align-items: center;
}

.mobile__icon {
	display: none;
}

.nav__link {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 26px;
	text-decoration-line: none;
	color: #000000;
	padding: 0px 12px;
	text-wrap: nowrap;
	cursor: pointer;
	margin: 0;
	border: none !important;
}

.dropdownLink {
	min-width: 120px !important;
	padding: 0 !important;
	margin: 0 !important;
}

.dropdownLink li {
	padding: 0 !important;
}

.dropdownLinkMobile li {
	padding: 0 !important;
}

.dropdownLinkMobile {
	padding: 0 !important;
	margin: 0 !important;
}

.findESBN a,
.ConfirmESBN a {
	margin: 0 !important;
	border: none !important;
}

.findESBN a:hover,
.ConfirmESBN a:hover {
	color: var(--green-03) !important;
}

.nav__link:hover {
	color: var(--green-03) !important;
}

.activeNavLink {
	color: var(--green-03) !important;
	text-decoration: underline !important;
}

.consolidatedSteps {
	color: #ffffff;
	background-color: #34aa63;
	padding: 10px;
	border-radius: 10px;
}

@media (max-width: 768px) {
	.header {
		height: 80px;
		margin: 0;
		padding: 14px 16px;
		align-items: center;
		background-color: #fff;
		min-width: 100%;
		max-width: 100%;
		position: fixed;
		/* top: 0; */
		z-index: 3;
	}

	.header > .desktop__nav {
		display: none;
	}

	.mobile__icon {
		display: block;
		cursor: pointer;
	}

	.header > .mobile__nav {
		width: 100%;
		height: 110vh;
		background: #fff;
		display: block;
		position: fixed;
		top: 80px;
		left: 0;
		padding: 0px 20px;
		z-index: 3;
	}

	.header > .mobile__nav ul {
		list-style: none;
	}

	.header > .mobile__nav > ul {
		padding: 0px;
	}

	.header > .mobile__nav li {
		padding: 30px 0px 0px 0px;
		font-size: 14px;
	}

	.paymentDropdownMobile {
		padding: 0;
	}

	.paymentDropdownMobile li {
		padding: 0 !important;
		text-decoration: none;
	}

	.header > .mobile__nav > li > ul {
		padding-left: 20px !important;
	}

	.mobile__nav .mobileActive:hover {
		text-decoration: underline;
	}

	.preHero {
		margin-top: 80px;
	}

	.desktop__header_logo {
		display: none;
	}

	.mobile__header_logo {
		display: block;
		height: 48px;
	}

	.mobile__nav > footer {
		width: 90%;
		display: flex;
		align-items: center;
		position: absolute;
		bottom: 17%;
		flex-direction: column;
	}

	.mobile__nav > footer .socials {
		display: flex;
		gap: 10px;
	}

	.mobile__nav > footer .socials > * {
		cursor: pointer;
	}

	.text__announcement_header {
		font-size: 28px;
		width: 100%;
		margin-top: 40px;
	}

	.text__announcement_description {
		margin-bottom: 40px;
		font-size: 18px;
	}

	.newsContent {
		padding: 3px 0 1px 0;
		font-size: 14px;
		line-height: 20px;
		color: #fff;
	}

	.socialMedia {
		width: 136px;
		display: flex;
		justify-content: space-between;
		border: none;
	}

	.credits {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
/* Navbar  ended  */
/* Headline  News*/
.preHero {
	width: 100%;
	background-color: #34aa63;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	overflow: hidden;
	white-space: nowrap;
	margin-top: 87px;
	z-index: 2;
}

.newsBg {
	font-size: 18px;
	line-height: 22.7px;
	color: #000000;
	background-color: #f78c01;
	padding: 12px 20px;
	z-index: 2;
	font-weight: 800;
}

.NewsBody {
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	color: #ffffff;
	animation: animateNewsHeadLine 90s linear infinite;
}

.NewsBody span {
	margin: 0 40px;
}

@keyframes animateNewsHeadLine {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		transform: translate3d(-100%, 0, 0);
	}
}

@media (max-width: 768px) {
	.preHero {
		width: 100%;
		margin-top: 80px;
	}
}

@media (max-width: 1024px) {
	.preHero {
		width: 100%;
	}
}
/* Headline News Ended*/ /* //modal /// */
