.h1 {
	font-size: 24px;
	font-weight: 600;
	line-height: 29.05px;
}
.h2 {
	font-size: 116px;
	font-weight: 400;
	line-height: 25.89px;
}

.title {
	font-size: 24px;
	font-weight: 600;
	line-height: 29.05px;
	letter-spacing: -3%;
}

.buttonContainer {
	display: flex;
	justify-content: flex-end;
	gap: 1rem;
	/* Space between buttons */
	margin-top: 1rem;
	/* Space above the buttons */
	margin-bottom: 48px;
}

.stylebtn {
	width: 204px;
	/* height: 17px; */
	line-height: 16.2px;
	font-weight: 500;
}
.stylebtn2 {
	width: 194px;
	/* height: 17px; */
	line-height: 16.2px;
	font-weight: 500;
}

.stylebtn1 {
	width: 68px;
	/* height: 17px; */
	line-height: 16.2px;
	font-weight: 500;
	height: 47px;
}

.doublebtns > div {
	width: calc(20% - 12px);
}
.doublebtns {
	display: flex;
	gap: 24px;
}

@media screen and (max-width: 768px) {
	.buttonContainer {
		flex-direction: column;
		align-items: center;
	}
	.stylebtn {
		width: 100%;
	}
}
/* Modal.css */
.modaloverlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal {
	background: white;
	border-radius: 8px;
	max-width: 800px;
	width: 100%;
	overflow-y: auto;
	padding: 40px;
	max-height: 90vh;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modalheader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #eee;
	padding-bottom: 10px;
}

.modalclosebutton {
	background: none;
	border: none;
	font-size: 24px;
	cursor: pointer;
}

.modalcontent {
	margin-top: 15px;
	width: 100%;
	font-size: 14px;
	/* Shrink text size */
	line-height: 1.4;
	/* Adjust line spacing */
}
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.companyName {
	font-size: 24px;
	font-weight: bold;
}

.viewBillButton {
	padding: 10px 20px;
	background-color: #00a65a;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.viewBillButton:hover {
	background-color: #008b4a;
}

.detailsSection {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.detailsGroup {
	flex: 1;
	margin-right: 20px;
}

.detailsGroup p {
	margin: 5px 0;
}

.pending {
	color: #ff9800;
	font-weight: bold;
}

.companyDetails {
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px solid #e0e0e0;
}

.companyDetails p {
	margin: 5px 0;
}
.radio__button_div > div {
	display: flex;
	/* Make the container a flexbox */
	gap: 30px;
	/* Add consistent spacing between radio button groups */
	margin-bottom: 20px;
	margin-top: 10px;
}

.radio__button_div > div > div {
	display: flex;
	/* Make each radio button group a flexbox */
	align-items: center;
	/* Align radio button and label vertically */
}

.radio__button_div > div > div input {
	margin-right: 10px;
	/* Add spacing between radio button and label */
}

.radio__button_div > div > div label {
	margin-top: 0;
	/* Reset any unnecessary margin */
}
