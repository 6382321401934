.form {
	display: flex;
	flex-direction: column;
	width: 350px;
	height: fit-content;
	margin-bottom: 45px;
}

.form label {
	font-weight: 500;
	font-size: 14px;
	line-height: 23px;
	color: var(--light-grey-08);
	margin-bottom: 8px;
}

.form input,
.form select {
	width: 100%;
	background: transparent;
	margin-bottom: 28px;
}

.submit__btn {
	width: 100%;
}

.receipt > table {
	margin: 0 !important;
}

.receipt__view {
	display: flex;
}

.receipt__view > table {
	margin: 0 !important;
}

.totalPaid {
	text-align: end;
	margin-top: 16px;
	margin-bottom: 30px;
	font-size: 16px;
	font-weight: 500;
}

@media (max-width: 768px) {
	.form {
		width: calc(100vw - 32px);
	}
	.totalPaid {
		padding-right: 16px;
	}
}
