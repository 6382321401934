.headerInfo {
	font-size: 24px;
	font-weight: 600;
	line-height: 29.05px;
	color: #202020;
	margin-bottom: 16px;
}

.header__btns {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
}

.header__btns .filter__button {
	display: flex;
	align-items: center;
	border: none;
	background: transparent;
	color: var(--green-01);
}

.h1,
.h2,
.h3 {
	margin-top: 0;
	margin-bottom: 0;
	margin: 0;
}

.assessmentInput {
	margin-top: 32px;
}

.hr {
	max-width: 736px;
	margin-top: 28px;
	margin-bottom: 28px;
}

.otherDetails {
	max-width: 736px;
}

.invisibleFileLabel {
	color: #005cb8;
	font-size: 14px;
	font-weight: 500;
	line-height: 22.7px;
	cursor: pointer;
}

.fileLists {
	width: 350px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
}

.fileLists span {
	margin-left: 5px;
	margin-bottom: 0;
	color: #267c48;
}
.downLoadedFileLists {
	background-color: #ffffff;
}

.downLoadedFile {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
}

.downLoadedFile span {
	margin-left: 5px;
	color: #202020;
}

.extendLayoutToFull {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -25vw;
	padding-right: 425px;
}

.instructionSection {
	background-color: #fbf9d0;
}

.instructionSection h4 {
	font-size: 12px;
	font-weight: 600;
	line-height: 16.2px;
	color: #202020;
	padding-top: 12px;
	margin-left: 12px;
}

.instruction {
	padding: 8px 30px;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	margin-bottom: 20px;
}

.downloadUploadSection {
	display: flex;
	justify-content: space-between;
	padding: 24px;
	background-color: #ececec;
	margin-bottom: 20px;
}

.downloadUploadSection h3 {
	color: #000000;
	font-size: 13px;
	font-weight: 500;
	line-height: 16.2px;
	margin: 0;
	text-transform: uppercase;
}

.downloadUploadSection button {
	margin-top: 8px;
}

.uploadArea {
	display: flex;
	align-items: center;
}

.uploadArea span {
	color: #000000;
	font-size: 14px;
	font-weight: 500;
	line-height: 22.7px;
	margin-left: 10px;
}

.inputContainer {
	position: relative;
	display: inline-block;
}

.currencySymbol {
	width: 13.33px;
	position: absolute;
	right: 10px;
	top: 50%;
	color: #000000;
	font-weight: 900;
	transform: translateY(-50%);
}
.detailsHeader {
	font-size: 20px;
	font-weight: 600;
	line-height: 24.2px;
	margin-bottom: 24px;
	color: #0c0c0c;
}

.subHeader {
	color: #0c0c0c;
	font-family: 24px;
	font-weight: 600;
	line-height: 29.05px;
	margin-bottom: 24px;
}

.printReceipt {
	display: flex;
	margin-right: 20px;
}
.printReceipt img {
	margin-right: 5px;
}

.printReceipt h3 {
	color: var(--green-02);
}

.whtViewDetails {
	display: flex;
	justify-content: space-between;
	margin-bottom: 64px;
}

.documentSection {
	width: 423px;
}
.documentHeader {
	display: flex;
	justify-content: space-between;
	padding: 14px 16px;
	gap: 24px;
	background: #d4f2e0;
}

.documentSection h4 {
	font-size: 13px;
	font-weight: 600;
	line-height: 16.2px;
	color: #202020;
}

.downloadAll {
	display: flex;
	align-items: center;
}

.downloadAll img {
	width: 16px;
	margin-left: 6px;
}

.AmountWithheld {
	display: flex;
	justify-content: space-between;
	margin: 0 90px;
}

.AmountWithheld p {
	font-size: 12px;
	font-weight: 600;
	line-height: 16.2px;
	color: var(--grey-09);
	text-transform: uppercase;
}

.colationSection {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.colationSection {
	font-size: 16px;
	font-weight: 400;
	line-height: 19.36px;
	color: #000000;
}

.billCloseButton {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 24px;
}

.billButton {
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	color: var(--primary-03);
}

.downloadPrintBtn {
	display: flex;
	justify-content: space-between;
	margin-top: 24px;
}

.viewSeparation {
	display: flex;
	justify-content: space-between;
}

.mobileDocument {
	margin-top: 24px;
	display: none;
}

/*MobileStyles*/
@media (max-width: 768px) {
	.extendLayoutToFull {
		width: 100%;
		left: 0;
		right: 0;
		margin-left: 0;
		padding: 0;
	}

	.mobileDocument {
		display: block;
	}

	.desktopDocuments {
		display: none;
	}
	.documentSection {
		width: 370px;
	}
}
